import React, {
	useEffect, useState
}from "react"
import {
	Switch, Route
}from "react-router-dom"
import {
	homeLoadables,
	profileLoadables,
	registerLoadables,
	loginLoadables,
	accountLoadables,
	contactsLoadables,
	messageToAdminLoadables,
	helpSectionLoadables,
	appealLoadables,
	managementLoadables,
	newsLoadables,
	calendarLoadables,
	uploadFileLoadables,
	resolutionLoadables
}from "./loadables"
import NotFound from "./components/NotFound"
import ReOrgReports from "./containers/nsi/ReOrgReReports/index"
import ReOrgReportReOrgs from "./containers/nsi/ReOrgReportReOrgs/index"
import {
	useSelector, useDispatch
}from "react-redux"
import {
	userActions
}from "./store/actions"
import agent from "./api/agent"
import notice from "./components/Notice"

const Routes = () => {
	const dispatch = useDispatch()
	const { user, isLoading, roles, userTypeName, systemEnvironment } = useSelector((state) => state.userReducer)

	useEffect(() => {
		if (user === undefined)
			dispatch(userActions.refreshUserData())
	}, [dispatch])
	return (
		<Switch>
			<Route
				exact
				component={ homeLoadables.LoadableHome }
				path="/"
			/>
			{ (roles.length == 0) && (
				<Route
					exact
					component={ registerLoadables.RestorePassword }
					path="/restorePassword"
				/>
			) }
			{ (roles.length != 0) && (
				<Route
					exact
					component={ accountLoadables.LoadableNsi }
					path="/nsi"
				/>
			) }
			<Route
				exact
				component={ profileLoadables.ConfirmEmail }
				path="/confirmEmail"
			/>
			{ (roles.includes("admin") ||
        roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin") ||
        roles.includes("manager") ||
        roles.includes("executor") ||
        roles.includes("registrator") ||
        roles.includes("reportMaster") ||
		roles.includes("callCenter")) && (
				<Route
					exact
					component={ accountLoadables.LoadableFastReportMenu }
					path="/services"
				/>
			) }
			{ roles.includes("reportMaster") && user?.reOrg && (
				<Route
					exact
					component={ accountLoadables.LoadableCreateReport }
					path="/createReOrgReport"
				/>
			) }
			<Route
				exact
				component={ contactsLoadables.LoadableContactsForm }
				path="/contacts"
			/>
			<Route
				exact
				component={ helpSectionLoadables.LoadableHelp }
				path="/help"
			/>
			<Route
				exact
				component={ helpSectionLoadables.LoadableHelpPost }
				path="/helpPost/:id"
			/>
			{ roles.includes("admin") && (
				<Route
					exact
					component={ helpSectionLoadables.LoadableCreatePost }
					path="/help/createPost/:id"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ helpSectionLoadables.LoadableManageHelp }
					path="/help/manageHelp"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ helpSectionLoadables.LoadableModifyTags }
					path="/help/modifyTags"
				/>
			) }
			<Route
				exact
				component={ newsLoadables.LoadableNewsMainPage }
				path="/news"
			/>
			<Route
				exact
				component={ newsLoadables.LoadableOneNews }
				path="/oneNews/:id"
			/>
			{ roles.length !== 0 && (
				<Route
					component={ appealLoadables.LoadableAppeals }
					path="/appeals"
				/>
			) }
			{ roles.length !== 0 && (
				<Route
					component={ appealLoadables.LoadableOneAppealView }
					path="/appeal/:id"
				/>
			) }
			{ /* {((user && !user.reOrg) ||
        roles.includes("headOfOrg") ||
        roles.includes("viceHeadOfOrg")) && (
        <Route
          component={appealLoadables.CollectiveAppealsList}
          path="/collectiveAppeals"
        />
      )} */ }
			{ ((user && !user.reOrg) ||
        roles.includes("headOfOrg") ||
        roles.includes("viceHeadOfOrg")) && (
				<Route
					component={ appealLoadables.CollectiveAppealView }
					path="/collectiveAppeal/:id"
				/>
			) }
			{ /* {roles.length !== 0 && ( */ }
			<Route
				component={ appealLoadables.JointToAppeal }
				path="/jointToAppeal/:id"
			/>
			{ /* )} */ }
			{ /* {roles.length !== 0 && ( */ }
			<Route
				component={ appealLoadables.PrivateJointToAppeal }
				path="/privateJointToAppeal/:id"
			/>
			{ /* )} */ }
			{ roles.length !== 0 && (
				<Route
					exact
					component={ accountLoadables.LoadableAccount }
					path="/account"
				/>
			) }
			{ roles.length !== 0 && (
				<Route
					exact
					component={ accountLoadables.LoadableMessages }
					path="/messages"
				/>
			) }
			{ roles.length !== 0 && (
				<Route
					exact
					component={ accountLoadables.LoadableNotifications }
					path="/notifications"
				/>
			) }
			{ roles.length !== 0 && (
				<Route
					exact
					component={ profileLoadables.Profile }
					path="/profile"
				/>
			) }
			{ roles.length !== 0 && (
				<Route
					exact
					component={ profileLoadables.Profile }
					path="/profile/:id"
				/>
			) }
			{ (roles.includes("manager") ||
        roles.includes("registrator") ||
        roles.includes("headOfOrg") ||
        roles.includes("viceHeadOfOrg")) && (
				<Route
					exact
					component={ appealLoadables.LoadableAppealRequestForms }
					path="/appealRequestForms"
				/>
			) }
			{ ((user && (!user.reOrg || user.isSelfRegistratedJurdical)) ||
        roles.includes("headOfOrg") ||
        roles.includes("viceHeadOfOrg")) && (
				<Route
					exact
					component={ appealLoadables.LoadableAppealsForm }
					path="/appealsForm"
				/>
			) }
			{ user && user.reOrg &&
        (roles.includes('registrator') ||
				 roles.includes('headOfOrg') ||
				 roles.includes('viceHeadOfOrg') ||
				roles.includes('manager')) && (
				<Route
					exact
					component={ appealLoadables.LoadableAppealCreateForm }
					path="/appealsCreateForm"
				/>
			) }
			{ user && user.reOrg && (
				<Route
					exact
					component={ appealLoadables.LoadableAppealEditForm }
					path="/appealsEditForm/:id"
				/>
			) }
			{ roles.length === 0 && (
				<Route
					exact
					component={ systemEnvironment && systemEnvironment.selfRegistrationLocked
						? registerLoadables.RegisterLocked
						: registerLoadables.Register }
					path="/register"
				/>
			) }
			{ roles.length === 0 && (
				<Route
					exact
					component={ loginLoadables.Login }
					path="/login"
				/>
			) }
			{ roles.length === 0 && (
				<Route
					exact
					component={ loginLoadables.RedirectPage }
					path="/oauth/return"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("callCenter")) && (
				<Route
					exact
					component={ managementLoadables.Users }
					path="/users"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ managementLoadables.ContentManagement }
					path="/contentManagement"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("externalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.MessageTemplates }
					path="/messageTemplates/:id?"
				/>
			) }
			{ roles.includes("applicant") && (
				<Route
					exact
					component={ managementLoadables.Invitation }
					path="/invitation/:id"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("callCenter")) && (
				<Route
					exact
					component={ registerLoadables.RegisterByAdmin }
					path="/registerByAdmin"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("callCenter")) && (
				<Route
					exact
					component={ managementLoadables.User }
					path="/users/:id"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ accountLoadables.LoadableNsiModify }
					path="/nsiModify"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ managementLoadables.ReportManagement }
					path="/reportsManagement"
				/>
			) }
			{ (roles.includes("admin") ||
        roles.includes("externalAdmin") ||
        roles.includes("manager") ||
        roles.includes("executor") ||
        roles.includes("registrator") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ accountLoadables.LoadableJsonUpload }
					path="/jsonUpload"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ calendarLoadables.Calendar }
					path="/calendar"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("reportMaster")) && (
				<Route
					exact
					component={ accountLoadables.LoadableConstructure }
					path="/constructure"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("reportMaster")) && (
				<Route
					exact
					component={ accountLoadables.LoadableTableReports }
					path="/tableReports"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("reportMaster")) && (
				<Route
					exact
					component={ accountLoadables.LoadableTableReportEditForm }
					path="/editTableReport/:id"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("reportMaster")) && (
				<Route
					exact
					component={ accountLoadables.LoadableTableReportCreateForm}
					path="/createTableReport"
				/>
			) }
			{ (roles.includes("admin") || roles.includes("reportMaster")) && (
				<Route
					exact
					component={ accountLoadables.LoadableTableReportUseForm}
					path="/useTableReport/:id"
				/>
			) }
			{ roles.includes("registrator") && (
				<Route
					exact
					component={ appealLoadables.LoadableRegForm }
					path="/appealsRegForm/:id"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ contactsLoadables.LoadableSetContacts }
					path="/setContacts"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ messageToAdminLoadables.LoadableMessageToAdmin }
					path="/messagesToAdmin"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ newsLoadables.LoadableManageNews }
					path="/manageNews"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ newsLoadables.LoadableAddNews }
					path="/addNews"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ newsLoadables.LoadableAddNews }
					path="/editNews/:id"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ calendarLoadables.OrgCalendar }
					path="/orgCalendar"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ profileLoadables.LoadablePersonalReception }
					path="/personalReception"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ registerLoadables.RegisterByExternalAdmin }
					path="/registerByExternalAdmin"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.DisciplinaryActionsList }
					path="/disciplinaryActions"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.WebApi }
					path="/webApi"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.OrgUsers }
					path="/orgUsers"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.RemovedOrgUsers }
					path="/removedOrgUsers"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.InvitationToReOrg }
					path="/inviteToReOrg"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.OrgManagement }
					path="/orgManagement"
				/>
			) }
			{ (roles.includes("admin") ||
        roles.includes("externalAdmin") ||
        roles.includes("manager") ||
        roles.includes("executor") ||
        roles.includes("registrator") ||
        roles.includes("tempExternalAdmin") ||
		roles.includes("callCenter")) && (

				<Route
					exact
					component={ managementLoadables.AisMvManage }
					path="/AisMvManage"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.OrganizationUnits }
					path="/orgUnits"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.OrgUser }
					path="/orgUsers/:id"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ calendarLoadables.WorkSchedule }
					path="/workSchedule"
				/>
			) }
			{ /* {(roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) &&
        userTypeName !== "individual" && (
          <Route
            path="/reOrgReSubthemeOgulsList"
            component={managementLoadables.ReOrgReSubthemeOgulsList}
            exact
          />
        )} */ }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) &&
        userTypeName !== "individual" && (
				<Route
					exact
					component={ managementLoadables.ChildOrgs }
					path="/childOrgs/:id"
				/>
			) }
			{ (roles.includes("externalAdmin") ||
        roles.includes("tempExternalAdmin")) && (
				<Route
					exact
					component={ managementLoadables.ChildOrgInvitation }
					path="/childOrgInvitation/:id"
				/>
			) }
			{ roles.includes("admin") && (
				<Route
					exact
					component={ managementLoadables.AccessRightsAssignment }
					path="/accessRights"
				/>
			) }
			<Route
				exact
				component={ accountLoadables.LoadableIFULRedirect }
				path="/esiful/redirect"
			/>
			<Route
				exact
				component={ accountLoadables.LoadableBelpochtaRedirect }
				path="/npes/redirect"
			/>
			{ roles.includes('forAdministrativeUse') &&
			<Route
				exact
				component={ managementLoadables.ControlSubsystem }
				path="/controlSubsystem"
			/> }
			{ roles.includes('forAdministrativeUse') &&
			<Route
				exact
				component={ managementLoadables.ControlSubsystemOrg }
				path="/controlSubsystem/:id"
			/> }
			{ roles.includes('forAdministrativeUse') &&
			<Route
				exact
				component={ managementLoadables.ControlSubsystemOrgAppeal }
				path="/controlSubsystem/:id/:appealId"
			/> }
			{ user && user.reOrg && (
				<Route
					exact
					component={ resolutionLoadables.ResolutionView }
					path="/resolution/:id"
				/>
			) }
			{ (roles.includes("manager") ||
        roles.includes("executor") ||
        roles.includes("headOfOrg") ||
        roles.includes("registrator") ||
				roles.includes("externalAdmin") ||
        roles.includes("viceHeadOfOrg")) && (
				<Route
					exact
					component={ accountLoadables.LoadableTask }
					path="/tasks"
				/>
			) }
			<Route
				exact
				component={ NotFound }
				path="*"
			/>

			<Route
				exact
				component={ NotFound }
				path="*"
			/>
		</Switch>
	)
}
export default Routes
