import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'
const LoadableHeader = Loadable({
	loader  : () => import('../containers/Menu'),
	loading : () => <Loading />
})

export const headerLoadables = {
	LoadableHeader
}
