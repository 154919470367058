import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'
const LoadableFooter = Loadable({
	loader  : () => import('../components/Footer'),
	loading : () => <Loading />
})

export const footerLoadables = {
	LoadableFooter
}