const i18n = {
	successRegister: {
		1 : "Регистрация прошла успешно!",
		0 : "Рэгістрацыя прайшла паспяхова!"
	},
	successChangeData: {
		1 : "Данные успешно изменены.",
		0 : "Даныя паспяхова зменены."
	}
}

export default i18n
