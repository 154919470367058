import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'

const LoadableNewsMainPage = Loadable({
	loader  : () => import('../containers/News/NewsMainPage'),
	loading : () => <Loading />
})

const LoadableNewsList = Loadable({
	loader  : () => import('../containers/News/NewsList'),
	loading : () => <Loading />
})

const LoadableOneNews = Loadable({
	loader  : () => import('../containers/News/OneNews'),
	loading : () => <Loading />
})
const LoadableManageNews = Loadable({
	loader  : () => import('../containers/News/ManageNews'),
	loading : () => <Loading />
})
const LoadableAddNews = Loadable({
	loader  : () => import('../containers/News/ManageNews/createNews'),
	loading : () => <Loading />
})

export const newsLoadables = {
	LoadableNewsMainPage,
	LoadableNewsList,
	LoadableOneNews,
	LoadableManageNews,
	LoadableAddNews
}