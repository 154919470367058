import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const ResolutionView = Loadable({
	loader  : () => import("../containers/Appeals/Resolutions/ResolutionView"),
	loading : () => <Loading />
})

export const resolutionLoadables = {
	ResolutionView
}
