import {
	mobileConstants
}from "../../containers/MobilePhoneNumber/store/constants"
import {
	emailConstants
}from "../../containers/Profile/Email/store/constants"
import {
	userConstants
}from "../constants/user.constants"
import {
	globalActions
}from "../../containers/Menu/actions"
import {
	userActions
}from "../actions/user"

const globalMiddleware = (store) => (next) => (action) => {
	switch (action.type){
		case mobileConstants.GetCodes.REQUEST :
		case userConstants.RefreshUserData.REQUEST :
		case userConstants.Login.REQUEST :
			store.dispatch(globalActions.setGlobalLoadingVisible(true))
			break
		case mobileConstants.GetCodes.SUCCESS :
		case userConstants.RefreshUserData.SUCCESS :
		case mobileConstants.GetCodes.FAILURE :
		case userConstants.RefreshUserData.FAILURE :
		case userConstants.Login.SUCCESS :
		case userConstants.Login.FAILURE :
			store.dispatch(globalActions.setGlobalLoadingVisible(false))
			break

    /* case emailConstants.ChangeEmail.SUCCESS:
       case emailConstants.ConfirmEmail.SUCCESS:
       case mobileConstants.ChangeNumber.SUCCESS:
         {
         store.dispatch(userActions.refreshUserData());
       } */
	}
	next(action)
}

export default globalMiddleware
