import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const LoadableGetQuestions = Loadable({
	loader  : () => import("../containers/Questions/index"),
	loading : () => <Loading />
})

const LoadableModifyQuestion = Loadable({
	loader  : () => import("../containers/Questions/modify"),
	loading : () => <Loading />
})

export const questionLoadables = {
	LoadableGetQuestions,
	LoadableModifyQuestion
}